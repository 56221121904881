<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-expansion-panel :inputs="shukkaShoruiSakusei.inputs" :reactiveVar="submitData" :key="progress">
      <base-form
        :searchBtn="true"
        :resetBtn="true"
        :inputs="shukkaShoruiSakusei.inputs"
        :reactiveVar="submitData"
        :key="progress"
        @submit-form="apiGetPackingsReadyForShipment(submitData)"
      >
      </base-form>
    </base-expansion-panel>

    <v-card-text>
      <v-row justify="center" align="center" class="mx-0">
        <div v-for="icon in icons" :key="icon" class="mx-2">
          <v-avatar tile rounded :color="icon.color" :size="icon.size">
            <img :src="require(`@/assets/${icon.src}`)" />
          </v-avatar>
          <span> 合計：</span>
          <span
            :class="{
              'red--text text--darken-1':
                limitOverRegionLists[icon.courierName].length > 0 ||
                limitOverCountryLists[icon.courierName].length > 0 ||
                (DailyTotalLimits[icon.courierName].totalWeight > 0 &&
                  icon.totalWeight > DailyTotalLimits[icon.courierName].totalWeight) ||
                (DailyTotalLimits[icon.courierName].totalWeight > 0 &&
                  icon.totalWeight > DailyTotalLimits[icon.courierName].totalWeight) ||
                (DailyTotalLimits[icon.courierName].totalInsurance > 0 &&
                  icon.totalInsurance > DailyTotalLimits[icon.courierName].totalInsurance),
            }"
            >{{ icon.totalWeight.commaString(' kg') }}｜ {{ icon.totalPrice.commaString(' $') }}｜
            {{ icon.totalInsurance.commaString(`${icon.courier !== 1 ? '円' : '$'}`) }}
            <v-tooltip
              v-if="
                limitOverRegionLists[icon.courierName].length > 0 ||
                limitOverCountryLists[icon.courierName].length > 0 ||
                (DailyTotalLimits[icon.courierName].totalWeight > 0 &&
                  icon.totalWeight > DailyTotalLimits[icon.courierName].totalWeight) ||
                (DailyTotalLimits[icon.courierName].totalWeight > 0 &&
                  icon.totalWeight > DailyTotalLimits[icon.courierName].totalWeight) ||
                (DailyTotalLimits[icon.courierName].totalInsurance > 0 &&
                  icon.totalInsurance > DailyTotalLimits[icon.courierName].totalInsurance)
              "
              bottom
              color="red darken-2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar size="20" v-bind="attrs" v-on="on">
                  <v-icon color="red darken-1"> mdi-alert-circle </v-icon>
                </v-avatar>
              </template>
              <v-list-item-subtitle
                v-if="
                  DailyTotalLimits[icon.courierName].totalWeight > 0 &&
                  icon.totalWeight > DailyTotalLimits[icon.courierName].totalWeight
                "
              >
                {{
                  `- ${icon.name}の1日単位トータル重量制限（${
                    DailyTotalLimits[icon.courierName].totalWeight
                  }kg）を超えてます。`
                }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                v-if="
                  DailyTotalLimits[icon.courierName].totalPrice > 0 &&
                  icon.totalPrice > DailyTotalLimits[icon.courierName].totalPrice
                "
                >{{
                  `- ${icon.name}の1日単位トータル総額制限（${
                    DailyTotalLimits[icon.courierName].totalPrice
                  }$）を超えてます。`
                }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                v-if="
                  DailyTotalLimits[icon.courierName].totalInsurance > 0 &&
                  icon.totalInsurance > DailyTotalLimits[icon.courierName].totalInsurance
                "
                >{{
                  `- ${icon.name}の1日単位トータル補償価格制限（${
                    DailyTotalLimits[icon.courierName].totalInsurance
                  }${icon.courier !== 1 ? '円' : '$'}）を超えてます。`
                }}</v-list-item-subtitle
              >
              <v-list-item-subtitle v-if="limitOverRegionLists[icon.courierName].length > 0"
                >- 下記地域総重量、総額、補償価格いずれかの制限に達しています。</v-list-item-subtitle
              >
              <v-list-item-subtitle v-for="region in limitOverRegionLists[icon.courierName]" :key="region">
                <ul>
                  -
                  {{
                    region
                  }}
                </ul>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="limitOverCountryLists[icon.courierName].length > 0"
                >- 下記国の総重量、総額、補償価格いずれかの制限に達しています。</v-list-item-subtitle
              >
              <v-list-item-subtitle v-for="country in limitOverCountryLists[icon.courierName]" :key="country">
                <ul>
                  -
                  {{
                    country
                  }}
                </ul>
              </v-list-item-subtitle>
            </v-tooltip>
          </span>
        </div>
      </v-row>
    </v-card-text>
    <v-card-text v-show="hasNoReservationNumber">
      <v-row justify="center" align="center">
        <span class="red--text">{{ messages.HAS_NO_RESERVATION_NUMBER }}</span></v-row
      >
    </v-card-text>
    <base-table
      ref="table"
      item-key="shipping_seq_number"
      :minWidth="2700"
      multi-sort
      show-select
      :hasNoReservationNumber="hasNoReservationNumber"
      :headers="shukkaShoruiSakusei.headers"
      :items="packingsReadyForShipment"
      :item-class="itemClass"
      :seqWithNoReservationNumber="shippingSeqWithNoReservationNumber"
      :columnName="[
        // Shipping備考確認
        'item.shipping_bikou_flag',
        // Shipping備考
        'item.bikou_list',
        // 重量
        'item.total_weight',
        // Invoice総額
        'item.total_price',
        // 予約番号
        'item.reservation_number',
        // 出荷書類
        'item.shukkaShorui',
        // 印刷
        'item.shukkaShoruiPrint',
        // 書類差し替え
        'item.shoruisashikae',
        // 送付先変更
        'item.soufusaki',
        // ARMS入金あり/なし
        'item.armsNyukinshori',
        // ダブルチェック者名
        'item.doubleCheck',
        // クーリエAPIに通信
        'item.shukkaDataTsushin',
        // AWBダウンロードリンク
        'item.shipping_label_link',
        // ステータス
        'item.status_name',
        // クーリエ連絡
        'item.courier_contacted_flag',
        // センター連絡
        'item.center_contacted_flag',
        // 運営添付書類
        'item.operator_documents',
        // 主担当
        'item.main_shipping_data_checker',
        //送信結果チェック
        'item.manager_name',
        // 編集
        'item.editing',
      ]"
      @row-selected="rowSelected"
    >
      <template v-slot:[`item.shipping_bikou_flag`]="{ item }">
        <div class="d-flex justify-center align-center">
          <lazy>
            <v-checkbox
              v-if="item.shipping_description && item.editing"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.shipping_bikou_flag > 0"
              dense
              hide-details
              :key="item.shipping_bikou_flag"
              @change="item.newValue.shipping_bikou_flag = $event ? 1 : 0"
            ></v-checkbox>
            <v-checkbox
              v-else-if="item.shipping_description"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.shipping_bikou_flag > 0"
              dense
              hide-details
              disabled
            ></v-checkbox>
          </lazy>
        </div>
      </template>
      <template v-slot:[`item.total_weight`]="{ item }">
        <td v-if="item.total_weight != null" class="text-right">
          <div class="d-flex justify-center align-center">
            {{ item.total_weight.commaString(' kg') }}
            <v-tooltip top color="red darken-2">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-if="
                    item.total_weight > courierLimits?.[item.country_name]?.[item.courier_name]?.individualWeight &&
                    courierLimits?.[item.country_name]?.[item.courier_name]?.individualWeight > 0
                  "
                  size="20"
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="red darken-1"> mdi-alert-circle </v-icon>
                </v-avatar> </template
              ><span>{{
                `クーリエの総重量制限（${
                  courierLimits?.[item.country_name]?.[item.courier_name]?.individualWeight
                }kg）に達しています`
              }}</span>
            </v-tooltip>
          </div>
        </td>
      </template>
      <template v-slot:[`item.total_price`]="{ item }">
        <td v-if="item.total_price != null">
          <div class="d-flex justify-center align-center">
            {{ item.total_price.commaString('$') }}
            <v-tooltip top color="red darken-2">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-if="
                    (item.total_weight > courierLimits?.[item.country_name]?.[item.courier_name]?.individualPrice &&
                      courierLimits?.[item.country_name]?.[item.courier_name]?.individualPrice > 0) ||
                    (item.total_weight >
                      courierLimits?.[item.country_name]?.[item.courier_name]?.individualCompensation &&
                      courierLimits?.[item.country_name]?.[item.courier_name]?.individualCompensation > 0)
                  "
                  size="20"
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="red darken-1"> mdi-alert-circle </v-icon>
                </v-avatar>
              </template>
              <p
                v-if="
                  item.total_weight > courierLimits?.[item.country_name]?.[item.courier_name]?.individualPrice &&
                  courierLimits?.[item.country_name]?.[item.courier_name]?.individualPrice > 0
                "
              >
                {{
                  `クーリエの総額制限（${
                    courierLimits?.[item.country_name]?.[item.courier_name]?.individualPrice
                  }＄）に達しています。`
                }}
              </p>
              <p
                v-if="
                  item.total_weight > courierLimits?.[item.country_name]?.[item.courier_name]?.individualCompensation &&
                  courierLimits?.[item.country_name]?.[item.courier_name]?.individualCompensation > 0
                "
              >
                {{
                  `クーリエの補償制限（${
                    courierLimits?.[item.country_name]?.[item.courier_name]?.individualCompensation
                  }${item.courier != 1 ? '円' : '$'}）に達しています`
                }}
              </p>
            </v-tooltip>
          </div>
        </td>
      </template>

      <template v-slot:[`item.armsNyukinshori`]="{ item }">
        <div class="d-flex justify-center align-center">
          <lazy>
            <v-checkbox
              v-if="item.editing"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.arms_payment_received_flag > 0"
              dense
              hide-details
              :key="item.armsNyukinshori"
              @change="item.newValue.arms_payment_received_flag = $event ? 1 : 0"
            ></v-checkbox>
            <v-checkbox
              v-else
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.arms_payment_received_flag > 0"
              dense
              hide-details
              disabled
            ></v-checkbox>
          </lazy>
        </div>
      </template>

      <template v-slot:[`item.doubleCheck`]="{ item }">
        <div class="d-flex justify-center align-center">
          <lazy>
            <v-textarea
              v-if="item.editing"
              v-model="item.newValue.double_check"
              auto-grow
              class="pa-0 ma-0"
              :background-color="$vuetify.theme.dark ? 'black' : 'white'"
              dense
              rows="1"
              counter="200"
              :value="item.newValue.double_check"
              style="min-width: 100px"
            ></v-textarea>
            <span v-else>{{ item.double_check }}</span>
          </lazy>
        </div>
      </template>
      <template v-slot:[`item.bikou_list`]="{ item }">
        <v-textarea
          v-if="item.editing"
          v-model="item.newValue.bikou"
          auto-grow
          class="pa-0 ma-0"
          :background-color="$vuetify.theme.dark ? 'black' : 'white'"
          dense
          rows="1"
          hide-details="true"
          :value="item.newValue.bikou"
          style="min-width: 100px"
        ></v-textarea>
        <div v-else-if="item.bikou_list" style="min-width: 100px">
          <span style="line-break: anywhere" v-for="ele in item.bikou_list.split('\n')" :key="ele"
            >{{ ele }}<br
          /></span>
        </div>
      </template>
      <template v-slot:[`item.reservation_number`]="{ item }">
        <span v-if="!item.editing">{{ item.reservation_number }}</span>
        <v-textarea
          v-else
          v-model="item.newValue.reservation_number"
          auto-grow
          class="pa-0 ma-0"
          :background-color="$vuetify.theme.dark ? 'black' : 'white'"
          dense
          rows="1"
          hide-details="true"
          :value="item.newValue.reservation_number"
          style="min-width: 100px"
        ></v-textarea>
      </template>
      <template v-slot:[`item.status_name`]="{ item }">
        <span v-if="!item.editing || item.status === 6">{{ item.status_name }}</span>
        <v-btn v-else x-small color="primary" @click="showDangerStatusChangeConfirmModal(item)">出荷書類作成済み</v-btn>
      </template>
      <template v-slot:[`item.main_shipping_data_checker`]="{ item }">
        <span v-if="!item.editing">{{ item.main_shipping_data_checker }}</span>
        <v-textarea
          v-else
          v-model="item.newValue.main_shipping_data_checker"
          auto-grow
          class="pa-0 ma-0"
          :background-color="$vuetify.theme.dark ? 'black' : 'white'"
          dense
          rows="1"
          hide-details="true"
          :value="item.newValue.main_shipping_data_checker"
          style="min-width: 100px"
        ></v-textarea>
      </template>
      <template v-slot:[`item.shukkaShorui`]="{ item }">
        <lazy>
          <div class="d-flex justify-center align-center">
            <v-btn
              x-small
              color="primary"
              @click="
                downloadMergedExcels({
                  format: 'url',
                  shippingSeqNumbers: [item.shipping_seq_number],
                })
              "
              >DL</v-btn
            >
            <tool-tip
              v-if="item.no_battery_img_models && item.no_battery_img_models.length > 0"
              :message="item.no_battery_img_models?.join(',<br>')"
              classes="mx-2"
            ></tool-tip>
          </div>
        </lazy>
      </template>
      <template v-slot:[`item.shukkaShoruiPrint`]="{ item }">
        <lazy>
          <v-btn
            x-small
            color="primary"
            @click="
              downloadPrintExcel({
                shippingSeqNumbers: [item.shipping_seq_number],
              })
            "
            >印刷</v-btn
          >
        </lazy>
      </template>
      <template v-slot:[`item.shoruisashikae`]="{ item }">
        <lazy>
          <v-btn
            v-if="role"
            x-small
            color="primary"
            @click="
              uploadShipmentDocumentsModal = true;
              uploadingRow = item;
            "
            >UP
          </v-btn>
        </lazy>
      </template>
      <template v-slot:[`item.soufusaki`]="{ item }">
        <lazy>
          <div v-if="role" class="d-flex justify-center align-center">
            <v-btn
              x-small
              color="success"
              @click="
                methodFlag = 1;
                showShipmentModal(item, item.sent_to_courier_ship_api);
              "
              >{{ labels.BTN_CHANGE }}</v-btn
            >

            <v-avatar v-if="item.exists_tmp_changed_ship_to" size="20" class="ml-1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red darken-1" v-bind="attrs" v-on="on"> mdi-alert-circle </v-icon>
                </template>
                <span>会員様から送付先の一時変更リクエストがあります</span>
              </v-tooltip>
            </v-avatar>

            <v-avatar
              v-if="item.default_ship_to && item.default_ship_to !== item.ship_to_type"
              size="20"
              class="ml-1"
              tile
              ><v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red darken-1" v-bind="attrs" v-on="on"> mdi-airplane-alert </v-icon>
                </template>
                <span>送付先が変更されてます</span>
              </v-tooltip>
            </v-avatar>
          </div>
        </lazy>
      </template>
      <template v-slot:[`item.manager_name`]="{ item }">
        <div align="center" justify="center">
          <lazy>
            <v-btn
              v-if="item.sent_to_courier_ship_api === 1"
              x-small
              color="success"
              @click="
                methodFlag = 3;
                info = item;
                sendShippingSeqNumber = item.shipping_seq_number;
                showShipmentCheckModal = true;
              "
              >{{ labels.BTN_CHECK }}</v-btn
            >
          </lazy>
        </div>
        <div align="center" justify="center">
          <div class="manager-name-text" v-if="item.sent_to_courier_ship_api === 1">
            {{ item.shipping_data_checker }}
          </div>
          <span></span>
        </div>
      </template>
      <template v-slot:[`item.shukkaDataTsushin`]="{ item }">
        <lazy>
          <v-btn
            v-if="item.sent_to_courier_ship_api === 0 && role"
            x-small
            dark
            color="red accent-4 white--text"
            @click="
              methodFlag = 2;
              showShipmentModal(item, 1);
            "
            >{{ labels.BTN_EXECUTE }}</v-btn
          >
          <div>
            <v-btn
              v-if="item.sent_to_courier_ship_api === 2 && role && item.total_cartons > 40 && item.courier == 1"
              x-small
              dark
              color="success"
              @click="retreiveShipment(item)"
              >{{ labels.BTN_RETRIEVE }}<v-icon x-small>mdi-sync</v-icon></v-btn
            >
            <v-btn
              v-if="item.sent_to_courier_ship_api == 1 && role"
              x-small
              color="error"
              @click="showCourierCancelConfirmModal(item)"
              >{{ labels.BTN_CANCEL }}</v-btn
            >
            <v-btn v-if="role" plain text x-small color="red darken-1" @click="showHardCancelConfirmModal(item)"
              >{{ labels.BTN_UNDO_SHIP }}<v-icon x-small>mdi-skull-crossbones</v-icon></v-btn
            >
          </div>
        </lazy>
      </template>
      <template v-slot:[`item.shipping_label_link`]="{ item }">
        <lazy>
          <v-btn
            v-if="item.shipping_label_link"
            x-small
            color="primary"
            @click="downloadFile({ key: item.shipping_label_link })"
            >DL</v-btn
          >
        </lazy>
      </template>
      <template v-slot:[`item.center_contacted_flag`]="{ item }">
        <div class="d-flex justify-center align-center">
          <lazy>
            <v-checkbox
              v-if="item.editing"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.center_contacted_flag > 0"
              dense
              hide-details
              :key="item.center_contacted_flag"
              @change="item.newValue.center_contacted_flag = $event ? 1 : 0"
            ></v-checkbox>
            <v-checkbox
              v-else
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.center_contacted_flag > 0"
              dense
              hide-details
              disabled
            ></v-checkbox>
          </lazy>
        </div>
      </template>
      <template v-slot:[`item.courier_contacted_flag`]="{ item }">
        <div class="d-flex justify-center align-center">
          <lazy>
            <v-checkbox
              v-if="item.editing"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.courier_contacted_flag > 0"
              dense
              hide-details
              :key="item.center_contacted_flag"
              @change="item.newValue.courier_contacted_flag = $event ? 1 : 0"
            ></v-checkbox>
            <v-checkbox
              v-else
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.courier_contacted_flag > 0"
              dense
              hide-details
              disabled
            ></v-checkbox>
          </lazy>
        </div>
      </template>
      <template v-slot:[`item.operator_documents`]="{ item }">
        <lazy>
          <div v-if="role">
            <v-btn
              x-small
              color="primary"
              @click="
                uploadOperatorDocumentsModal = true;
                uploadingRow = item;
              "
              >UP</v-btn
            >
            <div v-if="!!item.operator_documents" class="text-caption">
              {{
                !item.operator_documents
                  ? ''
                  : item.operator_documents.length > 0
                    ? 'x' + item.operator_documents.length
                    : ''
              }}
            </div>
          </div>
        </lazy>
      </template>
      <template v-slot:[`item.editing`]="{ item }">
        <lazy>
          <div v-if="role">
            <v-btn v-if="item.editing" text icon color="green lighten-2" @click="updatePackingInfo(item)">
              <v-icon large>mdi-content-save</v-icon>
            </v-btn>
            <v-btn v-else text icon color="blue lighten-2" @click="editPacking(item)">
              <v-icon large>mdi-pencil-box</v-icon>
            </v-btn>
            <v-btn
              v-if="item.editing"
              text
              icon
              color="red lighten-2"
              @click="
                editPacking(item);
                item.newValue = {};
              "
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </lazy>
      </template>
    </base-table>

    <v-card-actions class="justify-center mb-1 pt-0">
      <v-btn v-if="role" color="error" :disabled="disabled || alreadySentToCourierApi" @click="revertShipping">{{
        labels.BTN_SHUKKA_SHIJI_KAIJO
      }}</v-btn>
      <v-btn v-if="role" color="success" :disabled="disabled" @click="showReservationModal = true">{{
        labels.BTN_YOYAKUBANGO_IKKI_NYURYOKU
      }}</v-btn>
      <v-btn
        color="info"
        :disabled="disabled"
        @click="
          downloadMergedExcels({
            format: 'url',
            shippingSeqNumbers: selectedShipments.map((row) => row.shipping_seq_number),
          })
        "
        >{{ labels.BTN_IKKI_DOWNLOAD }}</v-btn
      >
      <v-btn color="primary" :disabled="disabled" @click="showReviewModal = true">{{ labels.BTN_PREVIEW }}</v-btn>
      <v-btn
        color="info"
        :disabled="disabled"
        @click="
          downloadPrintExcel({
            shippingSeqNumbers: selectedShipments.map((row) => row.shipping_seq_number),
          })
        "
        >一括印刷用Excel DL</v-btn
      >
      <csv-result-dl
        :disabled="packingsReadyForShipment.length === 0"
        item-key="shipping_seq_number"
        :row-selected="selectedShipments"
      ></csv-result-dl>
    </v-card-actions>

    <v-dialog v-model="showReservationModal" max-width="600px">
      <v-card>
        <v-card-title class="pb-0">{{ labels.BTN_YOYAKUBANGO_IKKI_NYURYOKU }}</v-card-title>
        <v-text-field
          class="ma-3"
          v-model="reservationNumber"
          outlined
          dense
          hide-details="true"
          :label="labels.YOYAKU_BANGO"
        ></v-text-field>
        <v-list v-if="shippingSeqWithReservationNumber.length" class="py-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle v-html="messages.HAS_RESERVATION_NUMBER"></v-list-item-subtitle>
              <v-list-item-subtitle v-for="seq in shippingSeqWithReservationNumber" :key="seq"
                >• {{ seq }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions class="justify-end">
          <v-btn
            v-if="shippingSeqWithReservationNumber.length"
            color="primary"
            outlined
            @click="addReservationNumberWithoutOverwrite"
            >{{ labels.BTN_NOT_OVERWRITE }}</v-btn
          >
          <v-btn
            :color="shippingSeqWithReservationNumber.length ? 'error' : 'primary'"
            outlined
            @click="addReservationNumber"
            >{{ shippingSeqWithReservationNumber.length ? labels.BTN_OVERWRITE : labels.BTN_ADD }}</v-btn
          >
          <v-btn color="warning" text @click="showReservationModal = false">{{ labels.BTN_CANCEL }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ShippingChangeModal
      :show-modal="showDialog"
      :show-confirm="showShipmentConfirmModal"
      :member-info="info"
      :methodFlag="methodFlag"
      @click:confirm="handleConfirm"
      @click:send="handleShipmentConfirm"
      @close="handleClose"
    />
    <ShippingCheckModal
      :show-modal="showShipmentCheckModal"
      :member-info="info"
      :methodFlag="3"
      @click:confirm="handleConfirm"
      @click:send="handleShipmentConfirm"
      @close="handleClose"
    />
    <ShippingDocPreviewModal
      :show-modal="showReviewModal"
      :shipping-seq-numbers="selectedShippingSeqNumbers"
      @close="handleClose"
    />
    <UploadShipmentDocumentsModal :parent="$data" />
    <UploadOperatorDocumentsModal :parent="$data" />
    <DangerConfirmModal
      :show-modal="showCourierCancelModal"
      :message="dangerMessage"
      :confirmText="dangerConfirmText"
      @click:confirm="handleCourierCancel"
      @click:close="handleClose"
    />
    <DangerConfirmModal
      :show-modal="showHardCancelModal"
      :message="dangerMessage"
      :confirmText="dangerConfirmText"
      @click:confirm="handleHardCancel"
      @click:close="handleClose"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  components: {
    ShippingChangeModal: () =>
      import(/* webpackChunkName: "ShippingChangeModal" */ '@/components/ui/ShippingChangeModal.vue'),
    ShippingCheckModal: () =>
      import(/* webpackChunkName: "ShippingCheckModal" */ '@/components/ui/ShippingCheckModal.vue'),
    ShippingDocPreviewModal: () =>
      import(/* webpackChunkName: "ShippingDocPreviewModal" */ '@/components/ui/ShippingDocReviewModal.vue'),
    UploadShipmentDocumentsModal: () =>
      import(/* webpackChunkName: "UploadShipmentDocumentsModal" */ '@/components/ui/UploadShipmentDocumentsModal.vue'),
    UploadOperatorDocumentsModal: () =>
      import(/* webpackChunkName: "UploadOperatorDocumentsModal" */ '@/components/ui/UploadOperatorDocumentsModal.vue'),
    DangerConfirmModal: () =>
      import(/* webpackChunkName: "DangerConfirmModal" */ '@/components/ui/DangerConfirmModal.vue'),
    lazy: () => import(/* webpackChunkName: "Lazy" */ '@/components/ui/Lazy.vue'),
    ToolTip: () => import(/* webpackChunkName: "toolTip" */ '../components/ui/ToolTip.vue'),
  },
  name: 'ShukkaShoruiSakusei',

  computed: {
    ...mapGetters({
      clickedConfirm: 'ui/clickedConfirm',
      shukkaShoruiSakusei: 'defined/shukkaShoruiSakusei',
      labels: 'defined/labels',
      statusItems: 'defined/statusItems',
      messages: 'defined/messages',
      /**
       * クーリエ制限マスタ
       */
      list: 'courierLimitMaster/list',
      mode: 'ui/mode',
      packingsReadyForShipment: 'shukkaShoruiSakusei/packingsReadyForShipment',
      commonSearchConditions: 'common/searchConditions',
      shukkayoteiDate: 'shukkaShoruiSakusei/shukkayoteiDate',
      latestAfterTomorrow: 'shukkaShoruiSakusei/latestAfterTomorrow',
      path: 'defined/pathNames',
      user: 'defined/user',
    }),
    hasNoReservationNumber() {
      return this.packingsReadyForShipment.some(
        ({ reservation_number }) => !reservation_number || reservation_number === '',
      );
    },
    shippingSeqWithReservationNumber() {
      return this.selectedShippingSeqNumbers
        .map((x) =>
          this.packingsReadyForShipment
            ?.filter(({ reservation_number, shipping_seq_number }) => shipping_seq_number === x && reservation_number)
            ?.map(({ shipping_seq_number }) => shipping_seq_number),
        )
        .flat(Infinity);
    },
    shippingSeqWithNoReservationNumber() {
      return this.packingsReadyForShipment
        ?.map(({ reservation_number, shipping_seq_number }) =>
          !reservation_number || reservation_number === '' ? shipping_seq_number : null,
        )
        .filter((x) => x);
    },
    searchConditions() {
      return this.commonSearchConditions?.shukkaShoruiSakusei;
    },
    progress() {
      return this.searchConditions?.progress ?? null;
    },
    disabled() {
      return this.selectedShipments.length === 0;
    },
    alreadySentToCourierApi() {
      return this.selectedShipments.some(({ sent_to_courier_ship_api }) => sent_to_courier_ship_api === 1);
    },
    selectedShippingSeqNumbers() {
      return this.selectedShipments.map(({ shipping_seq_number }) => shipping_seq_number);
    },
    shippingSeqNumbers() {
      return this.packingsReadyForShipment.map(({ shipping_seq_number }) => shipping_seq_number);
    },
    /**
     * 一日単位のクーリエのトータル重要制限
     * @type {number}
     */
    DailyTotalLimits() {
      return {
        fedex: {
          totalWeight: this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'fedex')?.totalWeight ?? 0,
          totalPrice: this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'fedex')?.totalPrice ?? 0,
          totalInsurance:
            this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'fedex')?.totalCompensation ?? 0,
        },
        ups: {
          totalWeight: this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'ups')?.totalWeight ?? 0,
          totalPrice: this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'ups')?.totalPrice ?? 0,
          totalInsurance:
            this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'ups')?.totalCompensation ?? 0,
        },
        dhl: {
          totalWeight: this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'dhl')?.totalWeight ?? 0,
          totalPrice: this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'dhl')?.totalPrice ?? 0,
          totalInsurance:
            this.list?.find(({ countryCode }) => countryCode.toLowerCase() === 'dhl')?.totalCompensation ?? 0,
        },
      };
    },

    /**
     * 表に表示されている出荷分のトータル重量、総額、補償額をクーリエごとに分ける処理
     */
    shipmentTotalsByCourier() {
      return {
        fedex: {
          totalWeight: this.packingsReadyForShipment
            ?.map(({ total_weight, courier }) => (+courier === 1 ? +total_weight : 0))
            ?.reduce((a, b) => a + b, 0),
          totalPrice: this.packingsReadyForShipment
            ?.map(({ total_price, courier }) => (+courier === 1 ? +total_price : 0))
            ?.reduce((a, b) => a + b, 0),
          totalInsurance: this.packingsReadyForShipment
            ?.map(({ insurance_price, courier }) => (+courier === 1 ? +insurance_price : 0))
            ?.reduce((a, b) => a + b, 0),
        },
        ups: {
          totalWeight: this.packingsReadyForShipment
            ?.map(({ total_weight, courier }) => (+courier === 2 ? +total_weight : 0))
            ?.reduce((a, b) => a + b, 0),
          totalPrice: this.packingsReadyForShipment
            ?.map(({ total_price, courier }) => (+courier === 2 ? +total_price : 0))
            ?.reduce((a, b) => a + b, 0),
          totalInsurance: this.packingsReadyForShipment
            ?.map(({ insurance_price, courier }) => (+courier === 2 ? +insurance_price : 0))
            ?.reduce((a, b) => a + b, 0),
        },
        dhl: {
          totalWeight: this.packingsReadyForShipment
            ?.map(({ total_weight, courier }) => (+courier === 3 ? +total_weight : 0))
            ?.reduce((a, b) => a + b, 0),
          totalPrice: this.packingsReadyForShipment
            ?.map(({ total_price, courier }) => (+courier === 3 ? +total_price : 0))
            ?.reduce((a, b) => a + b, 0),
          totalInsurance: this.packingsReadyForShipment
            ?.map(({ insurance_price, courier }) => (+courier === 3 ? +insurance_price : 0))
            ?.reduce((a, b) => a + b, 0),
        },
      };
    },

    /**
     * 表に表示される出荷分トータルを地域毎に分ける処理
     */
    shipmentTotalsByRegion() {
      const totals = {
        fedex: {},
        ups: {},
        dhl: {},
      };
      // 各クーリエに地域プロパティを追加する
      this.packingsReadyForShipment.map(({ region, courier }) => {
        // 99 その他地域
        if (+region !== 99 && courier)
          totals[+courier === 1 ? 'fedex' : +courier === 2 ? 'ups' : +courier === 3 ? 'dhl' : 'others'][region] = {
            totalWeight: 0,
            totalPrice: 0,
            totalInsurance: 0,
          };
      });
      // 各クーリエの地域プロパティにそれぞれのトータルを入れる
      this.packingsReadyForShipment.map(({ total_weight, total_price, insurance_price, region, courier }) => {
        const courierName = +courier === 1 ? 'fedex' : +courier === 2 ? 'ups' : +courier === 3 ? 'dhl' : 'others';
        if (+region !== 99 && courier) {
          totals[courierName][region]['totalWeight'] += +total_weight;
          totals[courierName][region]['totalPrice'] += +total_price;
          totals[courierName][region]['totalInsurance'] += +insurance_price;
        }
      });
      return totals;
    },
    /**
     * 表に表示される出荷分トータルを国毎に分ける処理
     */
    shipmentTotalsByCountries() {
      const totals = {
        fedex: {},
        ups: {},
        dhl: {},
      };
      this.packingsReadyForShipment.map(({ country_name, courier }) => {
        totals[+courier === 1 ? 'fedex' : +courier === 2 ? 'ups' : 'dhl'][country_name] = {
          totalWeight: 0,
          totalPrice: 0,
          totalInsurance: 0,
        };
      });
      this.packingsReadyForShipment.map(({ total_weight, total_price, insurance_price, country_name, courier }) => {
        const courierName = +courier === 1 ? 'fedex' : +courier === 2 ? 'ups' : 'dhl';
        totals[courierName][country_name]['totalWeight'] += +total_weight;
        totals[courierName][country_name]['totalPrice'] += +total_price;
        totals[courierName][country_name]['totalInsurance'] += +insurance_price;
      });
      return totals;
    },

    /**
     * 地域コード
     */
    regionNames() {
      const names = {};
      this.list.forEach(({ region, areaCode }) => {
        if (+areaCode < 100) {
          names[areaCode] = region;
        }
      });
      return names;
    },
    /**
     * 制限を超えた地域を絞り込む処理
     */
    limitOverRegions() {
      const fedex = {};
      const ups = {};
      const dhl = {};
      const overRegionLimit = (region = '', shipment = {}, courierId = 1) => {
        const { totalWeight, totalPrice, totalCompensation } = this.courierLimitsByRegion(region, courierId);
        return +totalWeight > 0 || +totalPrice > 0 || +totalCompensation > 0
          ? (+totalWeight > 0 ? shipment.totalWeight > totalWeight : false) ||
              (+totalPrice > 0 ? shipment.totalPrice > totalPrice : false) ||
              (+totalCompensation > 0 ? shipment.totalInsurance > totalCompensation : false)
          : false;
      };
      for (const [courier, courierData] of Object.entries({
        fedex,
        ups,
        dhl,
      })) {
        for (const [region, shipment] of Object.entries(this.shipmentTotalsByRegion[courier])) {
          if (overRegionLimit(region, shipment, courier === 'fedex' ? 1 : courier === 'ups' ? 2 : 3))
            courierData[region] = true;
        }
      }
      return {
        fedex: fedex,
        ups: ups,
        dhl: dhl,
      };
    },
    /**
     * 制限を超えた国を絞り込む処理
     */
    limitOverCountries() {
      const fedex = {};
      const ups = {};
      const dhl = {};
      const overCountryLimit = (countryName = '', shipment = {}, courierId = 1) => {
        const { totalWeight, totalPrice, totalCompensation } = this.courierLimitsByCountries(countryName, courierId);
        return +totalWeight > 0 || +totalPrice > 0 || +totalCompensation > 0
          ? (+totalWeight > 0 ? shipment.totalWeight > totalWeight : false) ||
              (+totalPrice ? shipment.totalPrice > totalPrice : false) ||
              (+totalCompensation > 0 ? shipment.totalInsurance > totalCompensation : false)
          : false;
      };
      for (const [courier, courierData] of Object.entries({
        fedex,
        ups,
        dhl,
      })) {
        for (const [countryName, shipment] of Object.entries(this.shipmentTotalsByCountries[courier])) {
          if (overCountryLimit(countryName, shipment, courier === 'fedex' ? 1 : courier === 'ups' ? 2 : 3))
            courierData[countryName] = true;
        }
      }
      return {
        fedex: fedex,
        ups: ups,
        dhl: dhl,
      };
    },

    /**
     * 制限を超えている地域のリスト
     * - アラートの下にリストとして表示される
     */
    limitOverRegionLists() {
      const fedex = [];
      const ups = [];
      const dhl = [];
      for (const [courier, courierData] of Object.entries({
        fedex,
        ups,
        dhl,
      })) {
        for (const regionCode in this.limitOverRegions[courier]) {
          if (this.limitOverRegions[courier][regionCode]) {
            courierData.push(this.regionNames[regionCode]);
          }
        }
      }
      return {
        fedex,
        ups,
        dhl,
      };
    },
    /**
     * 制限を超えている国のリスト
     * - アラートの下にリストとして表示される
     */
    limitOverCountryLists() {
      return {
        fedex: Object.keys(this.limitOverCountries['fedex']),
        ups: Object.keys(this.limitOverCountries['ups']),
        dhl: Object.keys(this.limitOverCountries['dhl']),
      };
    },

    /**
     * クーリエ単位の制限
     */
    courierLimits() {
      const limits = {};
      this.list?.map(
        ({ countryName }) =>
          (limits[countryName] = {
            FEDEX: {},
            UPS: {},
            DHL: {},
          }),
      );

      this.list.map(
        ({
          countryName,
          courierId,
          totalWeight,
          totalPrice,
          totalCompensation,
          individualWeight,
          individualPrice,
          individualCompensation,
        }) => {
          if (+courierId === 1) {
            limits[countryName]['FEDEX'] = {
              totalWeight,
              totalPrice,
              totalCompensation,
              individualWeight,
              individualPrice,
              individualCompensation,
            };
          } else if (+courierId === 2) {
            limits[countryName]['UPS'] = {
              totalWeight,
              totalPrice,
              totalCompensation,
              individualWeight,
              individualPrice,
              individualCompensation,
            };
          } else if (+courierId === 3) {
            limits[countryName]['DHL'] = {
              totalWeight,
              totalPrice,
              totalCompensation,
              individualWeight,
              individualPrice,
              individualCompensation,
            };
          }
        },
      );
      return limits;
    },
  },
  data() {
    return {
      icons: [
        {
          courier: 1,
          src: 'fedex_icon.svg',
          color: null,
          size: 48,
          courierName: 'fedex',
          name: 'FedEx',
          totalWeight: 0,
          totalPrice: 0,
          totalInsurance: 0,
        },
        {
          courier: 2,
          src: 'ups_icon.svg',
          color: null,
          size: 32,
          courierName: 'ups',
          name: 'UPS',
          totalWeight: 0,
          totalPrice: 0,
          totalInsurance: 0,
        },
        {
          courier: 3,
          src: 'dhl_icon.svg',
          color: 'orange',
          size: 28,
          courierName: 'dhl',
          name: 'DHL',
          totalWeight: 0,
          totalPrice: 0,
          totalInsurance: 0,
        },
      ],
      showDialog: false,
      showCourierCancelModal: false,
      showHardCancelModal: false,
      showDangerStatusChangeModal: false,
      showShipmentConfirmModal: false,
      showShipmentCheckModal: false,
      showReviewModal: false,
      info: null,
      selectedShipments: [],
      reservationNumber: null,
      showReservationModal: false,
      uploadShipmentDocumentsModal: null,
      uploadOperatorDocumentsModal: null,
      uploadingRow: null,
      role: false,
      confirmText: '',
      dangerMessage: '',
      methodFlag: 1,
      sendShippingSeqNumber: '',
      submitData: {
        shukkayoteiDate: this.shukkayoteiDate,
      },
    };
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.SHUKKA_SHORUI_SAKUSEI,
      authority: this.user.authority,
    });
    await this.initialize();
  },
  updated() {
    this.icons.forEach((icon) => {
      if (icon.courier == 1) {
        icon.totalWeight = this.shipmentTotalsByCourier?.fedex?.totalWeight ?? 0;
        icon.totalPrice = this.shipmentTotalsByCourier?.fedex?.totalPrice ?? 0;
        icon.totalInsurance = this.shipmentTotalsByCourier?.fedex?.totalInsurance ?? 0;
      }
      if (icon.courier == 2) {
        icon.totalWeight = this.shipmentTotalsByCourier?.ups?.totalWeight ?? 0;
        icon.totalPrice = this.shipmentTotalsByCourier?.ups?.totalPrice ?? 0;
        icon.totalInsurance = this.shipmentTotalsByCourier?.ups?.totalInsurance ?? 0;
      }
      if (icon.courier == 3) {
        icon.totalWeight = this.shipmentTotalsByCourier?.dhl?.totalWeight ?? 0;
        icon.totalPrice = this.shipmentTotalsByCourier?.dhl?.totalPrice ?? 0;
        icon.totalInsurance = this.shipmentTotalsByCourier?.dhl?.totalInsurance ?? 0;
      }
    });
  },
  methods: {
    ...mapActions({
      apiListCourier: 'api/listCourier',
      apiGetPackingsReadyForShipment: 'api/getPackingsReadyForShipment',
      apiGetCountryList: 'api/getConstantList',
      setMode: 'ui/setMode',
      setSuccess: 'ui/setSuccess',
      apiUpdatePacking: 'api/updateEachPackingInfo',
      apiRevertShipment: 'api/revertShipment',
      apiAddReservationNumber: 'api/addReservationNumber',
      apiCreateShipmentFedEx: 'api/createShipmentFedex',
      apiRetreiveShipmentFedEx: 'api/retreiveShipmentFedex',
      apiCancelShipmentFedEx: 'api/cancelShipmentFedex',
      apiCreateShipmentUPS: 'api/createShipmentUPS',
      apiCancelShipmentUPS: 'api/cancelShipmentUPS',
      apiCreateShipmentDHL: 'api/createShipmentDHL',
      apiCancelShipmentDHL: 'api/cancelShipmentDHL',
      apiHardCancelShipment: 'api/hardCancelShipment',
      apiUpdateShipTo: 'api/updateShipTo',
      apiUpdateShipToTempChange: 'api/updateShipToTempChange',
      apiUpdateShipToManualInputs: 'api/updateShipToManualInputs',
      toggleShowModal: 'ui/toggleShowModal',
      setClickedConfirmFlag: 'ui/setClickedConfirmFlag',
      setModalTitle: 'ui/setModalTitle',
      setModalMessage: 'ui/setModalMessage',
      setModalSuccessBtnLabel: 'ui/setModalSuccessBtnLabel',
      setModalConfirmBtnLabel: 'ui/setModalConfirmBtnLabel',
      resetModalContents: 'ui/resetModalContents',
      downloadMergedExcels: 'api/downloadMergedExcels',
      downloadPrintExcel: 'api/downloadPrintExcel',
      downloadFile: 'api/downloadFile',
      getRole: 'common/getRole',
    }),
    ...mapMutations({
      setSubmitData: 'shukkaShoruiSakusei/setSubmitData',
      setProgress: 'shukkaShoruiSakusei/setProgress',
      setShippingDate: 'shukkaShoruiSakusei/setShippingDate',
    }),
    async initialize() {
      this.submitData = this.searchConditions;
      await Promise.all([this.apiGetCountryList(), this.apiListCourier()]);
    },
    editPacking(packing) {
      packing.editing = !packing.editing;
      packing.newValue.reservation_number = packing.reservation_number;
      packing.newValue.bikou = packing.bikou_list;
      packing.newValue.double_check = packing.double_check;
      packing.newValue.main_shipping_data_checker = packing.main_shipping_data_checker;
    },
    itemClass(item) {
      if (item.editing) {
        return 'highlight-green not-change-hover-color';
      }
      return '';
    },
    /**
     * 地域の制限をクーリエで検索して見つけたらトータルの制限をReturnする
     */
    courierLimitsByRegion(region, _courierId) {
      const found = this.list.find(({ countryCode, courierId }) => countryCode == region && +courierId == +_courierId);
      return found ?? { totalWeight: 0, totalPrice: 0, totalCompensation: 0 };
    },
    /**
     * 国の制限をクーリエで検索して見つけたらトータルの制限をReturnする
     */
    courierLimitsByCountries(_countryName, _courierId) {
      const found = this.list.find(
        ({ countryName, courierId }) => countryName == _countryName && +courierId == +_courierId,
      );
      return found ?? { totalWeight: 0, totalPrice: 0, totalCompensation: 0 };
    },
    rowSelected(selectedRows) {
      this.selectedShipments = selectedRows;
    },
    async revertShipping() {
      const seq = this.selectedShipments.map(({ shipping_seq_number }) => shipping_seq_number);
      await this.apiRevertShipment(seq);
      await this.initialize();
      await this.apiGetPackingsReadyForShipment(this.submitData);
    },
    async addReservationNumber(_, shippingSeqForReservationNos = null) {
      const formData = [
        shippingSeqForReservationNos ? shippingSeqForReservationNos : this.selectedShippingSeqNumbers,
        this.reservationNumber,
        this.user ? this.user.userName : 'SYSTEM',
      ];
      this.showReservationModal = false;
      await this.apiAddReservationNumber(formData);
      await this.initialize();
      await this.apiGetPackingsReadyForShipment(this.submitData);
      this.$refs.table.resetSelected();
    },
    async addReservationNumberWithoutOverwrite() {
      const shippingSeqForReservationNos = this.selectedShippingSeqNumbers.filter(
        (x) => this.shippingSeqWithReservationNumber.indexOf(x) === -1,
      );
      await this.addReservationNumber(null, shippingSeqForReservationNos);
    },
    async createShipment(data) {
      const payload = {
        shippingSeqNumber: data.shipping_seq_number,
        memberName: data.member_name_ja,
      };
      data.courier == 1
        ? await this.apiCreateShipmentFedEx(payload)
        : data.courier == 2
          ? await this.apiCreateShipmentUPS(payload)
          : data.courier == 3
            ? await this.apiCreateShipmentDHL(payload)
            : this.showModal();
      if (data.courier > 0 && data.courier < 4) {
        await this.initialize();
        await this.apiGetPackingsReadyForShipment(this.submitData);
      }
    },
    async retreiveShipment(data) {
      const payload = {
        shippingSeqNumber: data.shipping_seq_number,
        memberName: data.member_name_ja,
      };
      await this.apiRetreiveShipmentFedEx(payload);
      await this.initialize();
      await this.apiGetPackingsReadyForShipment(this.submitData);
    },
    async cancelShipment(data) {
      const payload = {
        shippingSeqNumber: data.shipping_seq_number,
        trackingNumber: data.tracking_number,
      };
      data.courier == 1
        ? await this.apiCancelShipmentFedEx(payload)
        : data.courier == 2
          ? await this.apiCancelShipmentUPS(payload)
          : data.courier == 3
            ? // DHLにはキャンセルapiはないがstatusをキャンセルにしてたことにしたい
              await this.apiCancelShipmentDHL(payload)
            : this.showModal();
      if (data.courier > 0 && data.courier < 4) {
        await this.initialize();
        await this.apiGetPackingsReadyForShipment(this.submitData);
      }
    },
    async updatePackingInfo(data) {
      data.editing = false;
      const packing = {
        ...data,
        shipping_bikou_flag: data.newValue.shipping_bikou_flag ?? data.shipping_bikou_flag,
        arms_payment_received_flag: data.newValue.arms_payment_received_flag ?? data.arms_payment_received_flag,
        center_contacted_flag: data.newValue.center_contacted_flag ?? data.center_contacted_flag,
        courier_contacted_flag: data.newValue.courier_contacted_flag ?? data.courier_contacted_flag,
        bikou: data.newValue.bikou,
        shipping_data_checker: data.newValue.shipping_data_checker,
        main_shipping_data_checker: data.newValue.main_shipping_data_checker,
        reservation_number: data.newValue.reservation_number,
        shipment_canceller:
          data.newValue.center_contacted_flag > data.center_contacted_flag ||
          data.newValue.courier_contacted_flag > data.courier_contacted_flag
            ? this.user.userName
            : null,
        double_check: data.newValue.double_check ?? data.double_check,
      };
      const updates = [
        packing.shipping_seq_number,
        packing.reservation_number,
        packing.bikou,
        packing.shipping_bikou_flag,
        packing.arms_payment_received_flag,
        packing.center_contacted_flag,
        packing.courier_contacted_flag,
        packing.shipment_canceller,
        null,
        null,
        null,
        this.user.userName,
        packing.shipping_data_checker,
        packing.main_shipping_data_checker,
        null,
        packing.double_check,
      ];
      await this.apiUpdatePacking(updates);
      await this.initialize();
      await this.apiGetPackingsReadyForShipment(this.submitData);
    },
    async updateStatus(data) {
      data.editing = false;
      const updates = [
        data.shipping_seq_number,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.user.userName,
        null,
        null,
        6,
        null,
      ];
      await this.apiUpdatePacking(updates);
      await this.submitForm();
    },
    submitForm() {
      this.apiGetPackingsReadyForShipment(this.submitData);
    },
    showShipmentModal(info, showChange) {
      this.showDialog = true;
      this.info = info;
      // 0 -> show shipment address modification
      if (showChange === 0) {
        this.showShipmentConfirmModal = false;
      }
      // 1 -> show confirmation before sending to api
      if (showChange === 1) {
        this.showShipmentConfirmModal = true;
      }
    },
    async handleConfirm(
      sameShipToAndSameShippingFeePayer,
      shipToType,
      manualShipToInputs,
      memberId,
      shippingSeqNumber,
      matterNoList,
      shippingFeePayer,
      shippingFeePayerAcc,
      shippingFeePayerCompanyName,
      taxPayerAccNo,
      taxPayerCompanyName,
      description,
    ) {
      this.methodFlag = 1;
      this.showReviewModal = false;
      if (sameShipToAndSameShippingFeePayer && shipToType !== 5) {
        // 同じ送付先と同じ送料負担者が選択された場合 do nothing
        // 手動入力(shipTotype=5)は↓の処理
        this.showDialog = false;
        return;
      } else if (shipToType == 5) {
        // 手動入力
        const params = [JSON.stringify([manualShipToInputs]), shippingSeqNumber];
        await this.apiUpdateShipToManualInputs(params);
      } else {
        // 送付先1, 送付先2, 送付先3, 一時変更, 送料負担者変更
        const params = [
          shipToType,
          memberId,
          shippingSeqNumber,
          matterNoList,
          shippingFeePayer,
          shippingFeePayerAcc,
          shippingFeePayerCompanyName,
          taxPayerAccNo,
          taxPayerCompanyName,
          description,
        ];
        await this.apiUpdateShipTo(params);
      }
      this.showDialog = false;
      // テーブルをリフレッシュ/新データに更新
      await this.apiGetPackingsReadyForShipment(this.submitData);
    },

    showShipmentConfirm(data) {
      this.showShipmentConfirmModal = true;
      this.info = data;
    },
    async handleShipmentConfirm(data, manualShipToInputs) {
      if (this.methodFlag === 3) {
        await this.updatePackingInfo({
          shipping_seq_number: data.shipping_seq_number,
          newValue: {
            shipping_data_checker: this.user.userName,
          },
        });
      } else {
        const params = [JSON.stringify([manualShipToInputs]), data.shipping_seq_number];
        await this.apiUpdateShipToManualInputs(params);
        await this.createShipment(data);
      }
      this.showDialog = false;
      this.showShipmentConfirmModal = false;
      this.showShipmentCheckModal = false;
      this.methodFlag = 1;
    },

    showDangerStatusChangeConfirmModal(info) {
      this.toggleShowModal();
      this.setModalTitle(this.labels.UPDATE_CONFIRM);
      this.setModalMessage(this.messages.UPDATE_CONFIRM);
      this.setModalConfirmBtnLabel(this.labels.BTN_CHANGE);
      this.setMode(this.labels.UPDATE);
      this.info = info;
    },
    showCourierCancelConfirmModal(info) {
      this.showCourierCancelModal = true;
      this.dangerConfirmText = info.shipping_seq_number;
      this.dangerMessage = this.messages.COURIER_SHIPMENT_CANCEL_CONFIRM;
      this.info = info;
    },
    showHardCancelConfirmModal(info) {
      this.showHardCancelModal = true;
      this.dangerConfirmText = info.shipping_seq_number;
      this.dangerMessage = this.messages.COURIER_SHIPMENT_HARD_CANCEL_CONFIRM;
      this.info = info;
    },
    async handleCourierCancel() {
      await this.cancelShipment(this.info);
      this.showCourierCancelModal = false;
    },
    async handleHardCancel() {
      await this.apiHardCancelShipment([this.info.shipping_seq_number, this.user.userName]);
      this.apiGetPackingsReadyForShipment(this.submitData);
      this.showHardCancelModal = false;
    },
    async handleDangerStatusChangeConfirm() {
      await this.updateStatus(this.info);
      this.setSuccess(true);
      this.setModalTitle('成功');
      this.setModalMessage(this.messages.SHIPPING_DOCUMENT_UPDATE_COMPLETE);
      this.setModalSuccessBtnLabel('閉じる');
    },

    handleClose() {
      this.showDialog = false;
      this.methodFlag = 1;
      this.showCourierCancelModal = false;
      this.showHardCancelModal = false;
      this.showDangerStatusChangeModal = false;
      this.dangerConfirmText = '';
      this.dangerMessage = '';
      this.showReviewModal = false;
      this.showShipmentConfirmModal = false;
      this.showShipmentCheckModal = false;
    },
    showModal() {
      this.toggleShowModal();
      this.setModalTitle('Info');
      this.setModalMessage('No api for this courier');
      this.setModalConfirmBtnLabel('OK');
    },
  },
  watch: {
    clickedConfirm(val) {
      if (val) {
        switch (this.mode) {
          case this.labels.UPDATE:
            this.handleDangerStatusChangeConfirm();
            break;
          default:
            this.setClickedConfirmFlag(false);
            this.toggleShowModal();
            break;
        }
      }
    },
    searchConditions(newVal) {
      this.submitData = newVal;
    },
    showReservationModal(newVal) {
      if (!newVal) this.reservationNumber = null;
    },
  },
  beforeDestroy() {
    this.$store.commit('shukkaShoruiSakusei/setPackingsReadyForShipment', []);
  },
};
</script>
<style scoped>
.manager-name-text {
  font-size: xx-small;
}
</style>
